import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Chip,
  Box,
  Fade,
  useTheme,
  Divider,
} from "@mui/material";
import {
  CheckCircle,
  LocalShipping,
  Inventory,
  Home,
  ErrorOutline,
  HelpOutline,
} from "@mui/icons-material";

const getStatus = (event) => {
  if (event.toLowerCase().includes("delivered")) return "delivered";
  if (event.toLowerCase().includes("out for delivery"))
    return "out-for-delivery";
  if (
    event.toLowerCase().includes("arrived") ||
    event.toLowerCase().includes("departed")
  )
    return "in-transit";
  if (event.toLowerCase().includes("accepted")) return "pre-transit";
  if (event.toLowerCase().includes("pre-shipment")) return "pre-shipment";
  return "unknown";
};

const getIcon = (status) => {
  switch (status) {
    case "delivered":
      return <CheckCircle />;
    case "out-for-delivery":
      return <LocalShipping />;
    case "in-transit":
      return <Inventory />;
    case "pre-transit":
    case "pre-shipment":
      return <Home />;
    case "error":
    case "failure":
      return <ErrorOutline />;
    default:
      return <HelpOutline />;
  }
};

const getStatusColor = (status, theme) => {
  switch (status) {
    case "delivered":
      return theme.palette.success.main;
    case "out-for-delivery":
      return theme.palette.info.main;
    case "in-transit":
      return theme.palette.warning.main;
    case "pre-transit":
    case "pre-shipment":
      return theme.palette.primary.main;
    case "error":
    case "failure":
      return theme.palette.error.main;
    default:
      return theme.palette.grey[500];
  }
};

const EventTimeline = ({ events }) => {
  const theme = useTheme();
  console.log(theme);

  return (
    <Card sx={{ maxWidth: 600, margin: "auto", borderRadius: 2, boxShadow: 3 }}>
      <CardHeader
        title={
          <Typography variant="h5" fontWeight="bold" textAlign="center">
            Tracking Timeline
          </Typography>
        }
        sx={{ color: "primary.contrastText" }}
      />
      <Divider sx={{ bgcolor: "rgba(0,0,0,0.2)" }} />
      <CardContent sx={{ maxHeight: 620, overflowY: "auto", px: 2 }}>
        {events.length === 0 ? (
          <Box textAlign="center" py={4}>
            <HelpOutline sx={{ fontSize: 64, color: "text.secondary" }} />
            <Typography variant="h6" color="text.secondary" mt={2}>
              No tracking information available at the moment.
            </Typography>
          </Box>
        ) : (
          <List>
            {events.map((event, index) => {
              const status = getStatus(event.Event);
              const statusColor = getStatusColor(status, theme);
              return (
                <Fade in={true} timeout={1000 + index * 100} key={index}>
                  <ListItem
                    alignItems="flex-start"
                    sx={{
                      mb: 2,
                      borderLeft: 3,
                      borderColor: statusColor,
                      bgcolor: `${statusColor}10`,
                      borderRadius: 2,
                      transition: "all 0.3s",
                      "&:hover": {
                        bgcolor: `${statusColor}20`,
                        transform: "translateX(2px)",
                      },
                    }}>
                    <ListItemAvatar sx={{ justifyContent: "center", mt: 3 }}>
                      <Avatar sx={{ bgcolor: statusColor }}>
                        {getIcon(status)}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography variant="h6" fontWeight="bold">
                          {event.Event}
                        </Typography>
                      }
                      secondary={
                        <Box>
                          <Chip
                            label={event.EventDateTime}
                            size="small"
                            sx={{
                              mb: 1,
                              mt: 0.5,
                              bgcolor: `${statusColor}30`,
                              color: "text.primary",
                            }}
                          />
                          <Typography variant="body2" color="text.secondary">
                            {event.EventAddress || "Address not available"}
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                </Fade>
              );
            })}
          </List>
        )}
      </CardContent>
    </Card>
  );
};

export default EventTimeline;
