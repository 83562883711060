import { Button, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useUserContext } from "../App";
import StatusComp from "../components/common/StatusComp";
import LoadingContainer from "../components/containers/LoadingContainer";
import PageContainer from "../components/containers/PageContainer";
import ConfirmDuplicate from "../components/modals/ConfirmDuplicate";
import Section from "../components/ui/Section";
import api from "../config/axios";
import env from "../config/env";
import routes from "../config/routes";
import { copyToClipboard, demoTimeline, formatDate } from "../utilities/misc";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import ConfirmRequestRefund from "../components/modals/ConfirmRequestRefund";
import CreateTicket from "../components/modals/CreateTicket";
import Loader from "../components/ui/Loader";

import EventTimeline from "../components/ui/EventTimeline";

export const track = (type, id) => {
  if (type.toLowerCase().includes("ups")) {
    window.open("https://www.ups.com/track?loc=en_US&requester=ST", "_blank");
  }
  if (type.toLowerCase().includes("usps")) {
    window.open(
      "https://tools.usps.com/go/TrackConfirmAction?tLabels=" + id,
      "_blank"
    );
  }
  if (type.toLowerCase().includes("fedex")) {
    window.open(
      "https://www.fedex.com/fedextrack/?action=track&trackingnumber=" +
        id +
        "&cntry_code=us",
      "_blank"
    );
  }
};

const ViewLabel = () => {
  const { id } = useParams();
  const location = useLocation();
  const queryString = location.search;
  const params = new URLSearchParams(queryString);
  const isNew = params.get("new");
  const navigate = useNavigate();
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(false);
  const [duplicating, setDuplicating] = useState(false);
  const [duplicateConfirmShow, setDuplicateConfirmShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [pdfDown, setPdfDown] = useState(false);
  const [requestRefundShow, setRequestRefundShow] = useState(false);

  const getOrder = async () => {
    setLoading(true);
    await api
      .get("/order/read/" + id)
      .then((res) => setOrder(res.data.order))
      .catch((err) => toast.error(err.response.data.message))
      .finally(() => setLoading(false));
  };

  const [downloading, setDownloading] = useState(false);
  const [downloading2, setDownloading2] = useState(false);

  const downloadPdf = async () => {
    setDownloading(true);
    await api
      .get(`/order/download/${id}`, env.downloadConfig)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "label.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => toast.error(err.response.data.message))
      .finally(() => setDownloading(false));
  };

  const openPdf = async () => {
    setDownloading2(true);
    await api
      .get(`/order/download/${id}`, env.downloadConfig)
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        window.open(url);
      })
      .catch((err) => toast.error(err.response.data.message))
      .finally(() => setDownloading2(false));
  };

  const { refresh } = useUserContext();

  const duplicateOrder = async () => {
    setDuplicating(true);
    await api
      .post("/order/duplicate/" + id, {})
      .then((res) => {
        toast.success(res.data.message);
        refresh();
        setDuplicateConfirmShow(false);
        navigate(routes.LABELS);
      })
      .catch((err) => toast.error(err.response.data.message))
      .finally(() => setDuplicating(false));
  };

  const requestRefund = async (message, id) => {
    setLoader(true);

    const params = {
      message,
    };

    await api
      .post("/order/request-refund/" + id, params)
      .then((res) => {
        toast.success(res.data.message);
        setLoader(false);
        setRequestRefundShow(false);
        getOrder();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoader(false);
      });
  };

  useEffect(() => {
    getOrder();
    // if order is new refresh data after 5s
    if (isNew) setTimeout(getOrder, 5000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [ticketShow, setTicketShow] = useState(false);

  return (
    <LoadingContainer loading={loading}>
      <PageContainer>
        {/* create the grid 70 % and 30% fully responsive */}
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Section
              title={isNew ? "Label created successfully" : "Label Details"}
              end={
                <Link to={"/labels"} className="yellow-text">
                  <Button>View all labels</Button>
                </Link>
              }>
              <Divider sx={{ bgcolor: "rgba(0,0,0,0.2)" }} mb={2} />
              <Grid container spacing={1} mt={1}>
                <Grid item xs={6} md={3}>
                  <DetailComp label="Type" val={order?.labelType?.name} />
                </Grid>
                <Grid item xs={6} md={3}>
                  <DetailComp
                    label="Weight"
                    val={`${order?.Weight} ${
                      order?.labelType?.uid?.includes("firstclass")
                        ? "Oz"
                        : "Lbs"
                    }`}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <DetailComp
                    label="Date and Time"
                    val={formatDate(order?.createdAt)}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <DetailComp label="ID" val={order?._id} />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={6} md={3}>
                  <DetailComp
                    half
                    label="Tracking Number"
                    val={
                      <Button onClick={() => copyToClipboard(order?.tracking)}>
                        {order?.tracking}{" "}
                        <NorthEastIcon sx={{ fontSize: 13 }} />
                      </Button>
                    }
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <DetailComp
                    sx={{ color: "success.main" }}
                    label="Amount"
                    val={<>${order?.price?.toFixed(2)}</>}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <DetailComp
                    label="Status"
                    val={<StatusComp status={order?.status} />}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <DetailComp
                    sx={{ mb: 2 }}
                    label="Notes:"
                    val={order?.statusMessage || "N/A"}
                  />
                </Grid>
              </Grid>

              <Divider sx={{ bgcolor: "rgba(0,0,0,0.2)" }} />
              <Typography mt={2} mb={2}>
                Sender's Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  {" "}
                  <DetailComp label="Contact name" val={order?.FromName} />
                </Grid>
                <Grid item xs={6} md={3}>
                  {" "}
                  <DetailComp label="Address Line A" val={order?.FromStreet} />
                </Grid>
                <Grid item xs={6} md={3}>
                  <DetailComp label="Address Line B" val={order?.FromStreet2} />
                </Grid>
                <Grid item xs={6} md={3}>
                  {" "}
                  <DetailComp
                    label="Phone Number"
                    val={order?.FromPhone || "N/A"}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={1} mb={2}>
                <Grid item xs={6} md={3}>
                  {" "}
                  <DetailComp
                    label="Company Name"
                    val={order?.FromCompany || "None"}
                  />
                </Grid>
                <Grid item xs={6} md={2}>
                  {" "}
                  <DetailComp label="Country" val={order?.FromCountry} />
                </Grid>
                <Grid item xs={6} md={2}>
                  {" "}
                  <DetailComp label="City" val={order?.FromCity} />
                </Grid>
                <Grid item xs={6} md={2}>
                  <DetailComp label="State" val={order?.FromState} />
                </Grid>
                <Grid item xs={6} md={2}>
                  <DetailComp label="Zip" val={order?.FromZip} />
                </Grid>
              </Grid>

              <Divider sx={{ bgcolor: "rgba(0,0,0,0.2)" }} />
              <Typography mt={2} mb={2}>
                Reciever's Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  {" "}
                  <DetailComp label="Contact name" val={order?.ToName} />
                </Grid>
                <Grid item xs={6} md={3}>
                  <DetailComp label="Address Line A" val={order?.ToStreet} />
                </Grid>
                <Grid item xs={6} md={3}>
                  <DetailComp label="Address Line B" val={order?.ToStreet2} />
                </Grid>
                <Grid item xs={6} md={3}>
                  <DetailComp
                    label="Phone Number"
                    val={order?.ToPhone || "N/A"}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={1} mb={2}>
                <Grid item xs={6} md={3}>
                  {" "}
                  <DetailComp
                    label="Company Name"
                    val={order?.ToCompany || "None"}
                  />
                </Grid>
                <Grid item xs={6} md={2}>
                  {" "}
                  <DetailComp label="Country" val={order?.ToCountry} />
                </Grid>
                <Grid item xs={6} md={2}>
                  {" "}
                  <DetailComp label="City" val={order?.ToCity} />
                </Grid>
                <Grid item xs={6} md={2}>
                  <DetailComp label="State" val={order?.ToState} />
                </Grid>
                <Grid item xs={6} md={2}>
                  <DetailComp label="Zip" val={order?.ToZip} />
                </Grid>
              </Grid>

              <Divider sx={{ bgcolor: "rgba(0,0,0,0.2)" }} />
              <>
                {order?.status === "completed" && (
                  <>
                    <Grid container mt={1} spacing={2} alignItems={"center"}>
                      <Grid item xs={4} md={2} textAlign={"center"}>
                        <Typography>Actions :</Typography>
                      </Grid>
                      {order.status !== "refundAccepted" && (
                        <Grid item xs={4} md={2}>
                          <Button
                            onClick={() => openPdf(order?._id)}
                            variant="outlined"
                            fullWidth
                            color="primary">
                            {downloading2 ? <Loader primary /> : "Open"}
                          </Button>
                        </Grid>
                      )}
                      {order.status !== "refundAccepted" && (
                        <Grid item xs={4} md={2}>
                          {" "}
                          <Button
                            onClick={() => downloadPdf(order?._id)}
                            variant="outlined"
                            fullWidth
                            color="warning">
                            {downloading ? <Loader primary /> : "Download"}
                          </Button>
                        </Grid>
                      )}
                      {order.status !== "refundAccepted" && (
                        <Grid item xs={4} md={2}>
                          {" "}
                          <Button
                            sx={{ whiteSpace: "nowrap" }}
                            title="Open ticket"
                            fullWidth
                            onClick={() => {
                              setTicketShow(true);
                            }}
                            variant="outlined"
                            color="primary">
                            Open Ticket
                          </Button>
                        </Grid>
                      )}

                      <Grid item xs={4} md={2}>
                        <Button
                          onClick={() => setDuplicateConfirmShow(true)}
                          variant="outlined"
                          fullWidth
                          color="success">
                          Duplicate
                        </Button>
                      </Grid>
                      <Grid item xs={4} md={2}>
                        <Button
                          onClick={() =>
                            track(order?.labelType?.name, order?.tracking)
                          }
                          fullWidth
                          variant="outlined"
                          color="primary">
                          Track
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            </Section>
          </Grid>
          <Grid item xs={12} md={4}>
            <EventTimeline events={order?.trackingDetails || []} />
          </Grid>
        </Grid>
      </PageContainer>
      <ConfirmDuplicate
        open={duplicateConfirmShow}
        onClose={() => setDuplicateConfirmShow(false)}
        action={() => duplicateOrder(order?._id)}
        loading={duplicating}
      />
      <ConfirmRequestRefund
        open={requestRefundShow}
        onClose={() => setRequestRefundShow(false)}
        action={(e) => requestRefund(e, order._id)}
        loading={loader}
      />
      <CreateTicket
        open={ticketShow}
        onClose={() => setTicketShow(false)}
        orderId={order?._id}
      />
    </LoadingContainer>
  );
};

const DetailComp = ({ label, val, desc, sx }) => (
  <Stack sx={{ fontSize: 15, ...sx }} gap={0.5}>
    <Typography sx={{ fontSize: 14 }} color="text.secondary">
      {label}
    </Typography>
    <Typography fontWeight={500}>{val}</Typography>
    <Typography fontWeight={500}>{desc}</Typography>
  </Stack>
);

export default ViewLabel;
