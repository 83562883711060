import React from "react";
import { Chip } from "@mui/material";
import {
  CheckCircle,
  LocalShipping,
  Inventory,
  Home,
  ErrorOutline,
  HelpOutline,
} from "@mui/icons-material";

const getStatus = (event) => {
  if (event.toLowerCase().includes("delivered")) return "delivered";
  if (event.toLowerCase().includes("out for delivery"))
    return "out-for-delivery";
  if (
    event.toLowerCase().includes("arrived") ||
    event.toLowerCase().includes("departed")
  )
    return "in-transit";
  if (event.toLowerCase().includes("accepted")) return "pre-transit";
  if (event.toLowerCase().includes("pre-shipment")) return "pre-shipment";
  return "unknown";
};

const getIcon = (status) => {
  switch (status) {
    case "delivered":
      return <CheckCircle />;
    case "out-for-delivery":
      return <LocalShipping />;
    case "in-transit":
      return <Inventory />;
    case "pre-transit":
    case "pre-shipment":
      return <Home />;
    case "error":
    case "failure":
      return <ErrorOutline />;
    default:
      return <HelpOutline />;
  }
};

const getChipProps = (status) => {
  switch (status) {
    case "delivered":
      return { color: "success" };
    case "out-for-delivery":
      return { color: "info" };
    case "in-transit":
      return { color: "warning" };
    case "pre-transit":
    case "pre-shipment":
      return { color: "secondary" };
    case "error":
    case "failure":
      return { color: "error" };
    default:
      return { color: "default" };
  }
};

const TrackingStatusChip = ({ event }) => {
  console.log(event);
  const status = getStatus(event);
  const chipProps = getChipProps(status);
  const icon = getIcon(status);

  return (
    <Chip
      label={status.replace("-", " ").toUpperCase()}
      {...chipProps}
      sx={{
        width: "fit-content",
        height: "fit-content",
        borderRadius: 1,
        fontSize: 11,
        padding: " 0.4em 0.2em",
        fontWeight: "normal",
        "& .MuiChip-icon": {
          color: "inherit",
        },
      }}
    />
  );
};

export default TrackingStatusChip;
