import {
  Button,
  Divider,
  Grid,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import PageContainer from "../components/containers/PageContainer";
import CustomTable from "../components/ui/CustomTable";
import Loader from "../components/ui/Loader";
import Section from "../components/ui/Section";
import api from "../config/axios";
import env from "../config/env";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import { copyToClipboard } from "../utilities/misc";
import { useUserContext } from "../App";

const labelStatusData = [
  {
    code: 0,
    status: "New",
  },
  {
    code: 1,
    status: "Working",
  },
  {
    code: 2,
    status: "Done",
  },
  {
    code: 3,
    status: "Error",
  },
  {
    code: 4,
    status: "Canceled",
  },
  {
    code: 5,
    status: "Refunded",
  },
];

const API = () => {
  const { user } = useUserContext();
  const [apiKey, setApiKey] = useState(user.apiKey);
  const [rLoading, setRLoading] = useState(false);
  const [types, setTypes] = useState([]);
  const [uspsTypes, setUSPSTypes] = useState([]);

  const renewKey = async () => {
    setRLoading(true);
    await api
      .get("/auth/renew")
      .then((res) => {
        setApiKey(res.data.apiKey);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setRLoading(false));
  };

  const readLabelTypes = async () => {
    await axios
      .get(env.BASE_API_URL + "/api/v2/order/readLabels", {
        headers: {
          "x-api-key": apiKey,
        },
      })
      .then((res) => {
        setTypes(res.data.labels);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const readUSPSLabelTypes = async () => {
    await axios
      .get(env.BASE_API_URL + "/api/v2/order/readUspsTypes", {
        headers: {
          "x-api-key": apiKey,
        },
      })
      .then((res) => {
        setUSPSTypes(res.data.labels);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    readLabelTypes();
    readUSPSLabelTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKey]);
  return (
    <PageContainer
      title="API"
      desc="Information about LabelEmporium Public API">
      <Stack spacing={2}>
        <ApiSection title="Authorization">
          <CustomTable fields={["Key", "Value", "Add to"]} w="30rem">
            <TableRow>
              <TableCell>x-api-key</TableCell>
              <TableCell sx={{ color: "primary.main" }}>
                <Button onClick={() => copyToClipboard(apiKey)}>
                  {apiKey} <NorthEastIcon sx={{ fontSize: 12 }} />{" "}
                </Button>
              </TableCell>
              <TableCell>Header</TableCell>
            </TableRow>
          </CustomTable>
          <div>
            <Button onClick={renewKey} variant="outlined">
              {rLoading ? <Loader /> : "Renew"}
            </Button>
          </div>
        </ApiSection>
        <ApiSection title="Label types">
          <SubSection title="Request">
            <CodeBlock>
              {` Method: GET
 URL: https://api.labelemporium.net/api/v2/order/readLabels`}
            </CodeBlock>
          </SubSection>
          <Grid container spacing={2}>
            {types?.map((type) => (
              <Grid item xs={6} lg={4}>
                <Section title={type?.name} sx={{ height: "100%" }}>
                  <Stack gap={1}>
                    <div>
                      <span className="text-muted">ID: </span>
                      <Button onClick={() => copyToClipboard(type?.uuid)}>
                        {" "}
                        {type?.uid}
                      </Button>
                    </div>
                    <div>
                      <span className="text-muted">Max Weight: </span>
                      <span>
                        {type?.maxWeight}{" "}
                        {type?.name?.includes("First-Class") ? "Oz" : "lbs"}
                      </span>
                    </div>
                    {/* <span className="text-muted">Prices: </span> */}
                  </Stack>
                </Section>
              </Grid>
            ))}
          </Grid>
        </ApiSection>
        <ApiSection title="Label Status">
          <CustomTable fields={["Code", "Status"]} w="13rem">
            {labelStatusData.map((l) => (
              <TableRow>
                <TableCell sx={{ color: "primary.main" }}>{l.code}</TableCell>
                <TableCell>{l.status}</TableCell>
              </TableRow>
            ))}
          </CustomTable>
        </ApiSection>
        <ApiSection title="Label Order Object">
          <CodeBlock>
            {` {
    ID: '00000000-0000-0000-0000-000000000000',
    User: '00000000-0000-0000-0000-000000000000',
    Type: 'UID from Label Types',
    TypeName: 'USPS Priority',
    Weight: 0,
    Price: 0,
    FromCity: '',
    FromCompany: '',
    FromCountry: '',
    FromName: '',
    FromPhone: '',
    FromState: '',
    FromStreet: '',
    FromStreet2: '',
    FromZip: '',
    Reference: '',
    Status: '',
    ToCity: '',
    ToCompany: '',
    ToCountry: '',
    ToName: '',
    ToPhone: '',
    ToState: '',
    ToStreet: '',
    ToStreet2: '',
    ToZip: '',
    Tracking: '',
  
  }`}
          </CodeBlock>
        </ApiSection>

        <ApiSection title="Create Order">
          <SubSection title="Request">
            <CodeBlock>
              {` Method: POST
 URL: https://api.labelemporium.net/api/v2/order/createOrder`}
            </CodeBlock>
          </SubSection>
          <ParameterTable
            values={[
              ["GUID", "Type", "UID from Label Types"],
              ["Float", "Weight", "Pacakge weight"],
              // ["Float", "Width", "Pacakge Width(optional for USPS)"],
              // ["Float", "Length", "Pacakge Length(optional for USPS)"],
              // ["Float", "Height", "Pacakge Height(optional for USPS)"],
              ["String", "ref1", "Package Reference1(optional)"],
              // [
              //   "String",
              //   "ref2",
              //   "Package Reference2(optional : used only for UPS)",
              // ],
              [
                "String",
                "FromCountry",
                "Sender country (Two-Letter Country Code)",
              ],
              ["String", "FromName", "Sender name"],
              ["String", "FromCompany", "Sender company (optional)"],
              ["String", "FromStreet", "Sender street address"],
              ["String", "FromStreet2", "Sender secondary address (optional)"],
              ["String", "FromCity", "Sender city"],
              ["String", "FromState", "Sender state (two letter state code)"],
              ["String", "FromZip", "Sender ZIP code"],
              ["String", "FromPhone", "Sender Phone number"],
              [
                "String",
                "ToCountry",
                "Receiver country (Two-Letter Country Code)",
              ],
              ["String", "ToName", "Receiver name"],
              ["String", "ToCompany", "Receiver company (optional)"],
              ["String", "ToStreet", "Receiver street address"],
              ["String", "ToStreet2", "Receiver secondary address (optional)"],
              ["String", "ToCity", "Receiver city"],
              ["String", "ToState", "Receiver state (two letter state code)"],
              ["String", "ToZip", "Receiver ZIP code"],
              ["String", "ToPhone", "Receiver Phone number"],
            ]}
          />
          <SubSection title="Success Response 200">
            <CodeBlock>
              {`{
	"success": true,
	"Error": "",
	"Data": {
		"order": [Order object here] 
	}
}`}
            </CodeBlock>
          </SubSection>
          <SubSection title="Fail Response 400">
            <CodeBlock>
              {`{
	"success": false,
	"error": "[Error here]",
	"data": {}
}`}
            </CodeBlock>
          </SubSection>
        </ApiSection>

        <ApiSection title="Order Info">
          <SubSection title="Request">
            <CodeBlock>
              {` Method: GET
 URL: https://api.labelemporium.net/api/v2/order/readOrder/:ID`}
            </CodeBlock>
          </SubSection>

          <SubSection title="Success Response 200">
            <CodeBlock>
              {`{
	"success": true,
	"error": "",
	"data": {
		"order": [Order object here]
	}
}`}
            </CodeBlock>
          </SubSection>
          <SubSection title="Fail Response 400">
            <CodeBlock>
              {`{
	"success": false,
	"error": "Order does not exist",
	"data": {}
}`}
            </CodeBlock>
          </SubSection>
        </ApiSection>
        <ApiSection title="Tracking Status">
          <SubSection title="Request">
            <CodeBlock>
              {` Method: GET
 URL: https://api.labelemporium.net/api/v2/order/getTrackingStatus/:ID
 Tracking Status will update in our system twice a day in our system so don't spam the API`}
            </CodeBlock>
          </SubSection>

          <SubSection title="Success Response 200">
            <CodeBlock>
              {`{
"success": true,
"message": "Tracking Status fetched successfully",
"error": "",
"data": {
	"Status": "latest status",
	"TrackingDetails": [TrackingDetails object here],
   }
}`}
            </CodeBlock>
          </SubSection>
          <SubSection title="Fail Response 400">
            <CodeBlock>
              {`{
	"success": false,
	"error": "Order does not exist",
	"data": {}
}`}
            </CodeBlock>
          </SubSection>
        </ApiSection>
        <ApiSection title="Orders History">
          <SubSection title="Request">
            <CodeBlock>
              {` Method: GET
 URL: https://api.labelemporium.net/api/v2/order/readOrders`}
            </CodeBlock>
          </SubSection>

          <SubSection title="Success Response 200">
            <CodeBlock>
              {`{
	"success": true,
	"error": "",
	"data": {
		"Orders": [
			[Order object here]
			[Order object here]
			...
		],
	}
}`}
            </CodeBlock>
          </SubSection>
          <SubSection title="Fail Response 400">
            <CodeBlock>
              {`{
	"success": false,
	"error": "",
	"data": {}
}`}
            </CodeBlock>
          </SubSection>
        </ApiSection>

        <ApiSection title="Download Order File">
          <SubSection title="Request">
            <CodeBlock>
              {` Method: GET
 URL: https://api.labelemporium.net/api/v2/order/download/:ID`}
            </CodeBlock>
          </SubSection>

          <SubSection title="Success Response 200">
            <CodeBlock>{`The raw PDF file is returned`}</CodeBlock>
          </SubSection>
          <SubSection title="Fail Response 404">
            <CodeBlock>
              {`{
	"success": false,
	"error": {},
}`}
            </CodeBlock>
          </SubSection>
        </ApiSection>

        {/* USPS International  */}
        <ApiSection title="USPS International Label types">
          <SubSection title="Request">
            <CodeBlock>
              {` Method: GET
 URL: https://api.labelemporium.net/api/v2/order/readUspsTypes`}
            </CodeBlock>
          </SubSection>
          <Grid container spacing={2}>
            {uspsTypes?.map((type) => (
              <Grid item xs={6} lg={4}>
                <Section title={type?.name} sx={{ height: "100%" }}>
                  <Stack gap={1}>
                    <div>
                      <span className="text-muted">ID: </span>
                      <Button onClick={() => copyToClipboard(type?.uuid)}>
                        {" "}
                        {type?._id}
                      </Button>
                    </div>
                    <div>
                      <span className="text-muted">Max Weight: </span>
                      <span>
                        {type?.maxWeight}{" "}
                        {type?.name?.includes("First-Class") ? "Oz" : "lbs"}
                      </span>
                    </div>
                    {/* <span className="text-muted">Prices: </span> */}
                  </Stack>
                </Section>
              </Grid>
            ))}
          </Grid>
        </ApiSection>
        <ApiSection title="Create USPS International Label Order Object">
          <CodeBlock>
            {` {
    ID: '00000000-0000-0000-0000-000000000000',
    User: '00000000-0000-0000-0000-000000000000',
    Type: 'UID from Label Types',
    TypeName: 'USPS Priority',
    Weight: 0,
    Width: 0,
    Height: 0,
    Length: 0,
    Price: 0,
    FromCity: '',
    FromCompany: '',
    FromCountry: '',
    FromFirstName: '',
    FromLastName: '',
    FromEmail: '',
    FromPhone: '',
    FromState: '',
    FromStreet: '',
    FromStreet2: '',
    FromZip: '',
    Status: '',
    ToCity: '',
    ToCompany: '',
    ToCountry: '',
    ToFirstName: '',
    ToLastName: '',
    ToPhone: '',
    ToEmail: '',
    ToState: '',
    ToStreet: '',
    ToStreet2: '',
    ToZip: '',
    itemDescription: '',
    itemValue: '',
    itemQuantity: '',
    HSTariffcode: '', 
  
  }`}
          </CodeBlock>
        </ApiSection>

        <ApiSection title="Create USPS International Order">
          <SubSection title="Request">
            <CodeBlock>
              {` Method: POST
 URL: https://api.labelemporium.net/api/v2/order/createUspsOrder`}
            </CodeBlock>
          </SubSection>
          <ParameterTable
            values={[
              ["GUID", "Type", "UID from Label Types"],
              ["Float", "Weight", "Pacakge weight"],
              ["Float", "Width", "Pacakge Width"],
              ["Float", "Length", "Pacakge Length"],
              ["Float", "Height", "Pacakge Height"],
              ["String", "itemDescription", "Package Item Description"],
              ["String", "itemValue", "Package Item Value"],
              ["Float", "itemQuantity", "Package Item Quantity"],
              ["String", "HSTariffcode", "HS Tariff code ( if available)"],
              ["String", "FromCountry", "Sender country "],
              ["String", "FromFirstName", "Sender First name"],
              ["String", "FromLastName", "Sender Last name"],
              ["String", "FromStreet", "Sender street address"],
              ["String", "FromStreet2", "Sender secondary address (optional)"],
              ["String", "FromCity", "Sender city"],
              ["String", "FromState", "Sender state "],
              ["String", "FromZip", "Sender ZIP code"],
              ["String", "FromPhone", "Sender Phone number"],
              ["String", "FromEmail", "Sender Email address"],
              ["String", "ToCountry", "Receiver country "],
              ["String", "ToFirstName", "Receiver First name"],
              ["String", "ToLastName", "Receiver Last name"],
              ["String", "ToStreet", "Receiver street address"],
              ["String", "ToStreet2", "Receiver secondary address (optional)"],
              ["String", "ToCity", "Receiver city"],
              ["String", "ToState", "Receiver state "],
              ["String", "ToZip", "Receiver ZIP code"],
              ["String", "ToPhone", "Receiver Phone number"],
              ["String", "ToEmail", "Receiver Email address"],
              [
                "String",
                "callback_url",
                "https://something/order/1230 (to get tracking number & pdf url in return)",
              ],
            ]}
          />
          <SubSection title="Success Response 200">
            <CodeBlock>
              {`{
	"success": true,
	"Error": "",
	"Data": {
		"order": [Order object here] 
	}
}`}
            </CodeBlock>
          </SubSection>
          <SubSection title="Fail Response 400">
            <CodeBlock>
              {`{
	"success": false,
	"error": "[Error here]",
	"data": {}
}`}
            </CodeBlock>
          </SubSection>
        </ApiSection>

        <ApiSection title="Order USPS International Info">
          <SubSection title="Request">
            <CodeBlock>
              {` Method: GET
 URL: https://api.labelemporium.net/api/v2/order/readUspsorder/:ID`}
            </CodeBlock>
          </SubSection>

          <SubSection title="Success Response 200">
            <CodeBlock>
              {`{
	"success": true,
	"error": "",
	"data": {
		"order": [Order object here]
	}
}`}
            </CodeBlock>
          </SubSection>
          <SubSection title="Fail Response 400">
            <CodeBlock>
              {`{
	"success": false,
	"error": "Order does not exist",
	"data": {}
}`}
            </CodeBlock>
          </SubSection>
        </ApiSection>
        <ApiSection title="Download USPS International Order File">
          <SubSection title="Request">
            <CodeBlock>
              {` Method: GET
 URL: https://api.labelemporium.net/api/v2/order/downloadUsps/:ID`}
            </CodeBlock>
          </SubSection>

          <SubSection title="Success Response 200">
            <CodeBlock>{`The raw PDF file is returned`}</CodeBlock>
          </SubSection>
          <SubSection title="Fail Response 404">
            <CodeBlock>
              {`{
	"success": false,
	"error": {},
}`}
            </CodeBlock>
          </SubSection>
        </ApiSection>
      </Stack>
    </PageContainer>
  );
};

const ApiSection = ({ title, children }) => (
  <Stack spacing={2}>
    <Typography sx={{ color: "#fff" }} variant="h6" fontWeight={500}>
      {title}
    </Typography>
    {children}
    <Divider sx={{ bgcolor: "rgba(0,0,0,0.2)" }} />
  </Stack>
);

const SubSection = ({ title, children }) => (
  <Stack spacing={1}>
    <Typography color="text.secondary" fontWeight={500}>
      {title}
    </Typography>
    {children}
  </Stack>
);

const CodeBlock = ({ children }) => (
  <Section sx={{ overflowX: "auto" }}>
    <pre>{children}</pre>
  </Section>
);

const ParameterTable = ({ values }) => (
  <SubSection title="Body">
    <CustomTable fields={["Type", "Parameter", "Description"]}>
      {values.map((v) => (
        <TableRow>
          <TableCell>{v[0]}</TableCell>
          <TableCell sx={{ color: "primary.main" }}>{v[1]}</TableCell>
          <TableCell>{v[2]}</TableCell>
        </TableRow>
      ))}
    </CustomTable>
  </SubSection>
);

export default API;
