import { toast } from "react-toastify";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import UpdateRoundedIcon from "@mui/icons-material/UpdateRounded";
import dayjs from "dayjs";

export const states = [
  {
    ID: "AK",
    Name: "Alaska",
    Country: "US",
  },
  {
    ID: "AL",
    Country: "US",
    Name: "Alabama",
  },
  {
    ID: "AR",
    Country: "US",
    Name: "Arkansas",
  },
  {
    ID: "AZ",
    Country: "US",
    Name: "Arizona",
  },
  {
    ID: "CA",
    Country: "US",
    Name: "California",
  },
  {
    ID: "CO",
    Country: "US",
    Name: "Colorado",
  },
  {
    ID: "CT",
    Country: "US",
    Name: "Connecticut",
  },
  {
    ID: "DC",
    Country: "US",
    Name: "District Of Columbia",
  },
  {
    ID: "DE",
    Country: "US",
    Name: "Delaware",
  },
  {
    ID: "FL",
    Country: "US",
    Name: "Florida",
  },
  {
    ID: "GA",
    Country: "US",
    Name: "Georgia",
  },
  {
    ID: "HI",
    Country: "US",
    Name: "Hawaii",
  },
  {
    ID: "IA",
    Country: "US",
    Name: "Iowa",
  },
  {
    ID: "ID",
    Country: "US",
    Name: "Idaho",
  },
  {
    ID: "IL",
    Country: "US",
    Name: "Illinois",
  },
  {
    ID: "IN",
    Country: "US",
    Name: "Indiana",
  },
  {
    ID: "KS",
    Country: "US",
    Name: "Kansas",
  },
  {
    ID: "KY",
    Country: "US",
    Name: "Kentucky",
  },
  {
    ID: "LA",
    Country: "US",
    Name: "Louisiana",
  },
  {
    ID: "MA",
    Country: "US",
    Name: "Massachusetts",
  },
  {
    ID: "MD",
    Country: "US",
    Name: "Maryland",
  },
  {
    ID: "ME",
    Country: "US",
    Name: "Maine",
  },
  {
    ID: "MI",
    Name: "Michigan",
    Country: "US",
  },
  {
    ID: "MN",
    Country: "US",
    Name: "Minnesota",
  },
  {
    ID: "MO",
    Country: "US",
    Name: "Missouri",
  },
  {
    ID: "MS",
    Country: "US",
    Name: "Mississippi",
  },
  {
    ID: "MT",
    Country: "US",
    Name: "Montana",
  },
  {
    ID: "NC",
    Country: "US",
    Name: "North Carolina",
  },
  {
    ID: "ND",
    Country: "US",
    Name: "North Dakota",
  },
  {
    ID: "NE",
    Country: "US",
    Name: "Nebraska",
  },
  {
    ID: "NH",
    Country: "US",
    Name: "New Hampshire",
  },
  {
    ID: "NJ",
    Country: "US",
    Name: "New Jersey",
  },
  {
    ID: "NM",
    Country: "US",
    Name: "New Mexico",
  },
  {
    ID: "NV",
    Country: "US",
    Name: "Nevada",
  },
  {
    ID: "NY",
    Country: "US",
    Name: "New York",
  },
  {
    ID: "OH",
    Name: "Ohio",
    Country: "US",
  },
  {
    ID: "OK",
    Name: "Oklahoma",
    Country: "US",
  },
  {
    ID: "OR",
    Name: "Oregon",
    Country: "US",
  },
  {
    ID: "PA",
    Name: "Pennsylvania",
    Country: "US",
  },
  {
    ID: "PR",
    Country: "US",
    Name: "Puerto Rico",
  },
  {
    ID: "RI",
    Country: "US",
    Name: "Rhode Island",
  },
  {
    ID: "SC",
    Country: "US",
    Name: "South Carolina",
  },
  {
    ID: "SD",
    Country: "US",
    Name: "South Dakota",
  },
  {
    ID: "TN",
    Country: "US",
    Name: "Tennessee",
  },
  {
    ID: "TX",
    Country: "US",
    Name: "Texas",
  },
  {
    ID: "UT",
    Country: "US",
    Name: "Utah",
  },
  {
    ID: "VA",
    Country: "US",
    Name: "Virginia",
  },
  {
    ID: "VT",
    Country: "US",
    Name: "Vermont",
  },
  {
    ID: "WA",
    Country: "US",
    Name: "Washington",
  },
  {
    ID: "WI",
    Country: "US",
    Name: "Wisconsin",
  },
  {
    ID: "WV",
    Country: "US",
    Name: "West Virginia",
  },
  {
    ID: "WY",
    Country: "US",
    Name: "Wyoming",
  },

  // write canada provinces here
  {
    ID: "AB",
    Country: "CA",
    Name: "Alberta",
  },
  {
    ID: "BC",
    Country: "CA",
    Name: "British Columbia",
  },
  {
    ID: "MB",
    Country: "CA",
    Name: "Manitoba",
  },
  {
    ID: "NB",
    Country: "CA",
    Name: "New Brunswick",
  },
  {
    ID: "NL",
    Country: "CA",
    Name: "Newfoundland and Labrador",
  },
  {
    ID: "NS",
    Country: "CA",
    Name: "Nova Scotia",
  },
  {
    ID: "NT",
    Country: "CA",
    Name: "Northwest Territories",
  },
  {
    ID: "NU",
    Country: "CA",
    Name: "Nunavut",
  },
  {
    ID: "ON",
    Country: "CA",
    Name: "Ontario",
  },
  {
    ID: "PE",
    Country: "CA",
    Name: "Prince Edward Island",
  },
  {
    ID: "QC",
    Country: "CA",
    Name: "Quebec",
  },
  {
    ID: "SK",
    Country: "CA",
    Name: "Saskatchewan",
  },
  {
    ID: "YT",
    Country: "CA",
    Name: "Yukon",
  },
];
export const Countries = [
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia, Plurinational State of Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, the Democratic Republic of the Cook Islands",
  "Costa Rica",
  "Côte d'Ivoire",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran, Islamic Republic of Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Democratic People's Republic of Korea",
  "Korea",
  "Kuwait",
  "Kyrgyzstan",
  "Lao",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestinian Territory, Occupied",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Réunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan, Province of China",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];
export const copyToClipboard = (content) => {
  navigator.clipboard.writeText(content);
  toast.success("Copied to clipboard!");
};

export const statusMap = {
  cancelled: {
    text: "Cancelled",
    color: "error.main",
    icon: <CloseRoundedIcon fontSize="small" />,
  },
  failed: {
    text: "Cancelled",
    color: "error.main",
    icon: <CloseRoundedIcon fontSize="small" />,
  },
  close: {
    text: "Closed",
    color: "success.main",
    icon: <DoneRoundedIcon fontSize="small" />,
  },
  completed: {
    text: "Fulfilled",
    color: "success.main",
    icon: <DoneRoundedIcon fontSize="small" />,
  },
  processing: {
    text: "Processing",
    color: "warning",
    icon: <DoneRoundedIcon fontSize="small" />,
  },
  open: {
    text: "Open",
    color: "primary.main",
    icon: <UpdateRoundedIcon fontSize="small" />,
  },
  paid: {
    text: "Paid",
    color: "warning",
    icon: <WarningRoundedIcon fontSize="small" />,
  },
  refundRequested: {
    text: <>Refund Requested</>,
    color: "warning.main",
    icon: <WarningRoundedIcon fontSize="small" />,
  },
  refundAccepted: {
    text: <>Refund Accepted</>,

    color: "success.main",
    icon: <DoneRoundedIcon fontSize="small" />,
  },
  refundReject: {
    text: <>Refund Rejected</>,
    color: "error.main",
    icon: <CloseRoundedIcon fontSize="small" />,
  },
  pending: {
    text: "Pending",
    color: "warning.main",
    icon: <UpdateRoundedIcon fontSize="small" />,
  },
  "waiting for customer response": {
    color: "warning",
    text: "Waiting",
    icon: <WarningRoundedIcon fontSize="small" />,
  },
};

export const statusInvoiveMap = {
  cancelled: {
    text: "Cancelled",
    color: "error.main",
    icon: <CloseRoundedIcon fontSize="small" />,
  },
  close: {
    text: "Closed",
    color: "success.main",
    icon: <DoneRoundedIcon fontSize="small" />,
  },
  completed: {
    text: "Fulfilled",
    color: "success.main",
    icon: <DoneRoundedIcon fontSize="small" />,
  },
  processing: {
    text: "Processing",
    color: "warning",
    icon: <DoneRoundedIcon fontSize="small" />,
  },
  open: {
    text: "Open",
    color: "primary.main",
    icon: <UpdateRoundedIcon fontSize="small" />,
  },
  paid: {
    text: "Paid",
    color: "success.main",
    icon: <WarningRoundedIcon fontSize="small" />,
  },
  refundRequested: {
    text: <>Refund Requested</>,
    color: "warning.main",
    icon: <WarningRoundedIcon fontSize="small" />,
  },
  refundAccepted: {
    text: <>Refund Accepted</>,

    color: "success.main",
    icon: <DoneRoundedIcon fontSize="small" />,
  },
  refundReject: {
    text: <>Refund Rejected</>,
    color: "error.main",
    icon: <CloseRoundedIcon fontSize="small" />,
  },
  pending: {
    text: "Pending",
    color: "warning.main",
    icon: <UpdateRoundedIcon fontSize="small" />,
  },
  "waiting for customer response": {
    color: "warning",
    text: "Waiting",
    icon: <WarningRoundedIcon fontSize="small" />,
  },
};

export const formatDate = (date, hideTime = false) =>
  dayjs(date).format("MMM D, YYYY" + (hideTime ? "" : " | h:mm A"));

export const demoTimeline = [
  {
    EventDateTime: "November 25, 2024 1:44 pm",
    Event: "Delivered, In/At Mailbox",
    EventAddress: "FORT MYERS FL 33919",
    State: "FL",
    City: "FORT MYERS",
    Zip: "33919",
    EventDateTimeInDateTimeFormat: "2024-11-25T13:44:00",
  },
  {
    EventDateTime: "November 25, 2024 9:54 am",
    Event: "Out for Delivery",
    EventAddress: "FORT MYERS FL 33919",
    State: "FL",
    City: "FORT MYERS",
    Zip: "33919",
    EventDateTimeInDateTimeFormat: "2024-11-25T09:54:00",
  },
  {
    EventDateTime: "November 25, 2024 4:28 am",
    Event: "Arrived at Post Office",
    EventAddress: "FORT MYERS FL 33907",
    State: "FL",
    City: "FORT MYERS",
    Zip: "33907",
    EventDateTimeInDateTimeFormat: "2024-11-25T04:28:00",
  },
  {
    EventDateTime: "November 25, 2024 3:22 am",
    Event: "Arrived at USPS Facility",
    EventAddress: "FORT MYERS FL 33907",
    State: "FL",
    City: "FORT MYERS",
    Zip: "33907",
    EventDateTimeInDateTimeFormat: "2024-11-25T03:22:00",
  },
  {
    EventDateTime: "November 24, 2024 8:19 am",
    Event: "In Transit to Next Facility",
    EventAddress: "  ",
    State: "",
    City: "",
    Zip: "",
    EventDateTimeInDateTimeFormat: "2024-11-24T08:19:00",
  },
  {
    EventDateTime: "November 24, 2024 8:05 am",
    Event: "Departed USPS Regional Facility",
    EventAddress: "FORT MYERS FL DISTRIBUTION CENTER  ",
    State: "",
    City: "FORT MYERS FL DISTRIBUTION CENTER",
    Zip: "",
    EventDateTimeInDateTimeFormat: "2024-11-24T08:05:00",
  },
  {
    EventDateTime: "November 24, 2024 4:00 am",
    Event: "Arrived at USPS Regional Facility",
    EventAddress: "FORT MYERS FL DISTRIBUTION CENTER  ",
    State: "",
    City: "FORT MYERS FL DISTRIBUTION CENTER",
    Zip: "",
    EventDateTimeInDateTimeFormat: "2024-11-24T04:00:00",
  },
  {
    EventDateTime: "November 24, 2024 1:18 am",
    Event: "Departed USPS Regional Facility",
    EventAddress: "YBOR CITY FL DISTRIBUTION CENTER  ",
    State: "",
    City: "YBOR CITY FL DISTRIBUTION CENTER",
    Zip: "",
    EventDateTimeInDateTimeFormat: "2024-11-24T01:18:00",
  },
  {
    EventDateTime: "November 23, 2024 10:16 pm",
    Event: "Arrived at USPS Regional Facility",
    EventAddress: "YBOR CITY FL DISTRIBUTION CENTER  ",
    State: "",
    City: "YBOR CITY FL DISTRIBUTION CENTER",
    Zip: "",
    EventDateTimeInDateTimeFormat: "2024-11-23T22:16:00",
  },
  {
    EventDateTime: "November 22, 2024 7:22 pm",
    Event: "Arrived at USPS Regional Facility",
    EventAddress: "SANTA CLARITA CA DISTRIBUTION CENTER  ",
    State: "",
    City: "SANTA CLARITA CA DISTRIBUTION CENTER",
    Zip: "",
    EventDateTimeInDateTimeFormat: "2024-11-22T19:22:00",
  },
  {
    EventDateTime: "September 24, 2024 ",
    Event: "Pre-Shipment, USPS Awaiting Item",
    EventAddress: "  ",
    State: "",
    City: "",
    Zip: "",
    EventDateTimeInDateTimeFormat: "2024-09-24T00:00:00",
  },
];
